import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAboutUsContent, updateAboutUsContent } from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const aboutUsContentGet = createAsyncThunk(
  'get/aboutUsContent',
  async (props, thunkAPI) => {
    try {
      const response = await getAboutUsContent();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const aboutUsContentUpdate = createAsyncThunk(
  'update/aboutUsContent',
  async (props, thunkAPI) => {
    try {
      const response = await updateAboutUsContent(props);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        Notification.error('Unauthorized.');
      } else if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
