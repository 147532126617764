import React from 'react';

import { Navigate, useLocation, Outlet } from 'react-router-dom';
import Auth from '../../../helpers/auth.service';

const RequireAuth = () => {
  const location = useLocation();

  if (!Auth.user || !Auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default RequireAuth;
