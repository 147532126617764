import { createSlice } from '@reduxjs/toolkit';

import Auth from '../../helpers/auth.service';
import { sendOtp, validateOtp } from './loginAction';

const initialState = {
  user: Auth.user,
  token: Auth.token,
  loading: false,
  successMessage: null,
  errorMessage: null,
  otpSent: false
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loading = false;
      state.successMessage = null;
      state.errorMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.errorMessage = null;
        if (action?.payload?.status === 200) {
          state.otpSent = true;
          state.successMessage = action?.payload?.data?.status;
        }
      })
      .addCase(sendOtp.pending, (state) => {
        state.user = null;
        state.token = null;
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.loading = false;
        state.otpSent = false;
        state.errorMessage = action?.payload?.response?.data?.status;
        state.successMessage = true;
        state.user = null;
        state.token = null;
      })
      .addCase(validateOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = null;
        if (action?.payload?.status === 200) {
          state.user = {
            name: action?.payload?.data?.data?.full_name,
            member_id: action?.payload?.data?.data?.member_id
          };
          state.token = action?.payload?.data?.data?.token?.access;
          state.otpSent = true;
          state.successMessage = action?.payload?.data?.status;
        }
      })
      .addCase(validateOtp.pending, (state) => {
        state.user = null;
        state.token = null;
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(validateOtp.rejected, (state, action) => {
        state.loading = false;
        state.otpSent = true;
        state.errorMessage = action?.payload?.response?.data?.status;
        state.successMessage = null;
        state.user = null;
        state.token = null;
      });
  }
});

export default loginSlice.reducer;
