import { createSlice } from '@reduxjs/toolkit';
import { aboutUsContentGet, aboutUsContentUpdate } from './aboutAction';

const initialState = {
  content: '',
  updatedContent: '',
  status: '',
  error: ''
};

const aboutUsContentSlice = createSlice({
  name: 'aboutUsContent',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(aboutUsContentGet.fulfilled, (state, action) => {
        state.content = action?.payload?.data?.content;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(aboutUsContentGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(aboutUsContentGet.rejected, (state, action) => {
        state.content = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(aboutUsContentUpdate.fulfilled, (state, action) => {
        state.updatedContent = action?.payload?.data?.content;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(aboutUsContentUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(aboutUsContentUpdate.rejected, (state, action) => {
        state.updatedContent = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default aboutUsContentSlice.reducer;
