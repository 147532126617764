import React from 'react';

import { ConfigProvider } from 'antd';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import RequireAuth from './components/common/auth/RequireAuth';
import SuspenseFallback from './components/common/SuspenseFallback';
import themeConfig from './constants/theme_constants';

import './assets/css/scss/theme.scss';

const LoginPage = React.lazy(() => import('./routes/Public/Login'));
const PrivatePages = React.lazy(() => import('./routes/Private'));

function App() {
  return (
    <ConfigProvider theme={{ token: themeConfig }}>
      <React.Suspense fallback={<SuspenseFallback />}>
        <ToastContainer />
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path="*" element={<PrivatePages />} />
          </Route>

          <Route path="login" element={<LoginPage />} />
        </Routes>
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App;
