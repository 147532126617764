import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addTemples,
  deleteTempleImage,
  deleteTemples,
  getTempleById,
  getTemples,
  updateTemples
} from '../../axios/axios';
import { citiesListGet, statesListGet } from '../master/masterAction';

import Notification from '../../helpers/Notification';

export const templesListGet = createAsyncThunk(
  'get/temples',
  async (props, thunkAPI) => {
    try {
      const response = await getTemples();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templesDetailGet = createAsyncThunk(
  'get/templeDetails',
  async (props, thunkAPI) => {
    try {
      const response = await getTempleById(props);
      const templesDetail = response.data.data;

      thunkAPI.dispatch(statesListGet(templesDetail?.temple_country));
      thunkAPI.dispatch(citiesListGet(templesDetail?.temple_state));

      return templesDetail;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templesAdd = createAsyncThunk(
  'post/temples',
  async (props, thunkAPI) => {
    try {
      const response = await addTemples(props);
      Notification.success('Successfully created.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templesUpdate = createAsyncThunk(
  'put/temples',
  async (props, thunkAPI) => {
    try {
      const response = await updateTemples(props.id, props.data);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templesDelete = createAsyncThunk(
  'delete/temples',
  async (props, thunkAPI) => {
    try {
      const response = await deleteTemples(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const templeImageDelete = createAsyncThunk(
  'delete/templeImage',
  async (props, thunkAPI) => {
    try {
      const response = await deleteTempleImage(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
