import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCommittee,
  deleteCommittee,
  getCommitteesList,
  updateCommittee
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const committeesListGet = createAsyncThunk(
  'get/committees',
  async (props, thunkAPI) => {
    try {
      const response = await getCommitteesList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeesUpdate = createAsyncThunk(
  'update/committees',
  async (props, thunkAPI) => {
    try {
      const response = await updateCommittee(props.id, props.data);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeesCreate = createAsyncThunk(
  'post/committees',
  async (props, thunkAPI) => {
    try {
      const response = await createCommittee(props);
      Notification.success('Successfully created.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeesDelete = createAsyncThunk(
  'delete/committees',
  async (props, thunkAPI) => {
    try {
      const response = await deleteCommittee(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
