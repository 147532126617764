import { createSlice } from '@reduxjs/toolkit';
import {
  approveMemberGet,
  memberDelete,
  memberDetailGet,
  membersListGet,
  memberUpdate
} from './membersAction';

const initialState = {
  membersList: [],
  membersDetail: {},
  deleteMember: {},
  updatedMember: {},
  approvedMember: '',
  status: '',
  error: ''
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(membersListGet.fulfilled, (state, action) => {
        state.membersList = action?.payload?.success_message;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(membersListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(membersListGet.rejected, (state, action) => {
        state.membersList = [];
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      })

      .addCase(memberDetailGet.fulfilled, (state, action) => {
        state.membersDetail = action?.payload;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(memberDetailGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(memberDetailGet.rejected, (state, action) => {
        state.membersDetail = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(memberUpdate.fulfilled, (state, action) => {
        state.updatedMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(memberUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(memberUpdate.rejected, (state, action) => {
        state.updatedMember = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(memberDelete.fulfilled, (state, action) => {
        state.deleteMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(memberDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(memberDelete.rejected, (state, action) => {
        state.deleteMember = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(approveMemberGet.fulfilled, (state, action) => {
        state.approvedMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(approveMemberGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(approveMemberGet.rejected, (state, action) => {
        state.approvedMember = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default membersSlice.reducer;
