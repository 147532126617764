import { createSlice } from "@reduxjs/toolkit";

import {
    getAchievementsList,
    deleteAchievements,
    getAchievementsById,
    updateAchievementsById,
    addAchievements
} from "./achievementsAction";

const initialState = {
    achievementsList: [],
    singleAchievement: {},
    loading: false,
    successMessage: null,
    errorMessage: null,
}

export const achievementsSlice = createSlice({
    name: 'memberAchievements',
    initialState,
    reducers: {

    },
    extraReducers: (builders) => {
        builders
            .addCase(getAchievementsList.fulfilled, (state, action) => {
                state.achievementsList = action?.payload?.data?.success_message
                state.loading = false
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(getAchievementsList.pending, (state) => {
                state.achievementsList = []
                state.loading = true
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(getAchievementsList.rejected, (state, action) => {
                state.achievementsList = []
                state.loading = false
                state.successMessage = null
                state.errorMessage = action?.payload?.response?.data?.status
            })
            .addCase(deleteAchievements.fulfilled, (state, action) => {
                state.achievementsList = []
                state.loading = false
                state.successMessage = action?.payload?.data?.success_message
                state.errorMessage = null
            })
            .addCase(deleteAchievements.pending, (state) => {
                state.achievementsList = []
                state.loading = true
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(deleteAchievements.rejected, (state, action) => {
                state.achievementsList = []
                state.loading = false
                state.successMessage = null
                state.errorMessage = action?.payload?.response?.data?.error_message
            })
            .addCase(getAchievementsById.fulfilled, (state, action) => {
                state.singleAchievement = action?.payload?.data?.success_message
                state.loading = false
                state.successMessage = action?.payload?.data?.success_message
                state.errorMessage = null
            })
            .addCase(getAchievementsById.pending, (state) => {
                state.singleAchievement = {}
                state.loading = true
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(getAchievementsById.rejected, (state, action) => {
                state.singleAchievement = {}
                state.loading = false
                state.successMessage = null
                state.errorMessage = action?.payload?.response?.data?.error_message
            })
            .addCase(updateAchievementsById.fulfilled, (state, action) => {
                state.loading = false
                state.successMessage = action?.payload?.data?.success_message
                state.errorMessage = null
            })
            .addCase(updateAchievementsById.pending, (state) => {
                state.loading = true
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(updateAchievementsById.rejected, (state, action) => {
                state.loading = false
                state.successMessage = null
                state.errorMessage = action?.payload?.response?.data?.error_message
            })
            .addCase(addAchievements.fulfilled, (state, action) => {
                state.loading = false
                state.successMessage = action?.payload?.data?.success_message
                state.errorMessage = null
            })
            .addCase(addAchievements.pending, (state) => {
                state.loading = true
                state.successMessage = null
                state.errorMessage = null
            })
            .addCase(addAchievements.rejected, (state, action) => {
                state.loading = false
                state.successMessage = null
                state.errorMessage = action?.payload?.response?.data?.error_message
            })
    }
})

export default achievementsSlice.reducer;
