import { createSlice } from '@reduxjs/toolkit';
import {
  templeImageDelete,
  templesAdd,
  templesDelete,
  templesDetailGet,
  templesListGet,
  templesUpdate
} from './samaj-darshanAction';

const initialState = {
  templesList: [],
  templesDetail: [],
  addedTemple: {},
  deletedTemple: {},
  deletedTempleImage: {},
  updatedTemple: {},
  status: '',
  error: ''
};

const templesSlice = createSlice({
  name: 'temples',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(templesListGet.fulfilled, (state, action) => {
        state.templesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templesListGet.rejected, (state, action) => {
        state.templesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(templesDetailGet.fulfilled, (state, action) => {
        state.templesDetail = action?.payload;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templesDetailGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templesDetailGet.rejected, (state, action) => {
        state.templesDetail = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(templesAdd.fulfilled, (state, action) => {
        state.addedTemple = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templesAdd.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templesAdd.rejected, (state, action) => {
        state.addedTemple = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(templesUpdate.fulfilled, (state, action) => {
        state.updatedTemple = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templesUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templesUpdate.rejected, (state, action) => {
        state.updatedTemple = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(templesDelete.fulfilled, (state, action) => {
        state.deletedTemple = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templesDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templesDelete.rejected, (state, action) => {
        state.deletedTemple = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(templeImageDelete.fulfilled, (state, action) => {
        state.deletedTempleImage = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(templeImageDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(templeImageDelete.rejected, (state, action) => {
        state.deletedTempleImage = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default templesSlice.reducer;
