import { createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../helpers/history';

import Auth from '../../helpers/auth.service';
import Request from '../../helpers/request.service';

export const sendOtp = createAsyncThunk(
  'login/sendOTP',
  async (props, { rejectWithValue }) => {
    try {
      return await Request.get(`/otp/${props.raidesh_id}/`);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const validateOtp = createAsyncThunk(
  'login/validateOTP',
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.post(`/otp/${props.raidesh_id}/`, {
        otp: props.otp
      });
      if (response.status === 200) {
        if (response?.data?.data?.token) {
          Auth.user = {
            name: response?.data?.data?.full_name,
            member_id: response?.data?.data?.member_id
          };
          Auth.token = response?.data?.data?.token?.access;
          history.push('/Members');
          return response;
        }
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
