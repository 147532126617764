import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCommitteeMember,
  deleteCommitteeMember,
  getCommitteeMembersList,
  getFilteredCommitteeMembersList,
  updateCommitteeMember
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const committeeMembersListGet = createAsyncThunk(
  'get/committee-members',
  async (props, thunkAPI) => {
    try {
      const response = await getCommitteeMembersList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const filteredCommitteeMembersListGet = createAsyncThunk(
  'get/filtered-committee-members',
  async (props, thunkAPI) => {
    try {
      const response = await getFilteredCommitteeMembersList(props);

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeeMembersUpdate = createAsyncThunk(
  'update/committee-members',
  async (props, thunkAPI) => {
    try {
      const response = await updateCommitteeMember(props.id, props.data);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeeMembersCreate = createAsyncThunk(
  'post/committee-members',
  async (props, thunkAPI) => {
    try {
      const response = await createCommitteeMember(props);
      Notification.success('Successfully created.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const committeeMembersDelete = createAsyncThunk(
  'delete/committee-members',
  async (props, thunkAPI) => {
    try {
      const response = await deleteCommitteeMember(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
