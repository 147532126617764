import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'light'
};

class Notification {
  /**
   * Clear ann toast notifications that are currently being displayed on page.
   */
  static clearAll() {
    toast.dismiss();
  }

  /**
   * Show info toast message.
   *
   * @param message
   */
  static info(message) {
    toast.info(message, toastConfig);
  }

  /**
   *
   * Show success toast message.
   *
   * @param message
   */
  static success(message) {
    toast.success(message, toastConfig);
  }

  /**
   * Show warning toast message.
   *
   * @param message
   */
  static warning(message) {
    toast.warning(message, toastConfig);
  }

  /**
   * SHow error toast message.
   *
   * @param message
   */
  static error(message) {
    toast.error(message, toastConfig);
  }
}

export default Notification;
