import { createAsyncThunk } from "@reduxjs/toolkit";
import { history } from "../../helpers/history";
import Notification from "../../helpers/Notification";

import Request from "../../helpers/request.service";


export const getAchievementsList = createAsyncThunk(
    'achievements/getAll',
    async (props, { rejectWithValue }) => {
        try {
            return await Request.get(`/achievements/`)
        } catch (err) {
            return rejectWithValue(err)
        }
    });


export const deleteAchievements = createAsyncThunk(
    'achievements/delete',
    async (props, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`/achievements/${props.id}/`)
            Notification.success(response?.data?.success_message)
            return response
        } catch (err) {
            Notification.err(err?.response?.data?.success_message)
            return rejectWithValue(err)
        }
    }
);

export const getAchievementsById = createAsyncThunk(
    'achievements/getByID',
    async (props, { rejectWithValue }) => {
        try {
            return await Request.get(`/achievements/${props.id}/`)
        } catch (err) {
            return rejectWithValue(err)
        }
    }
);

export const updateAchievementsById = createAsyncThunk(
    'achievements/updateByID',
    async (props, { rejectWithValue }) => {
        try {
            const response = await Request.putJson(`/achievements/${props.id}/`, props.body)
            if (response?.status === 200) {
                Notification.success(response?.data?.success_message)
                history.push('../Members-achievements')
            }
            return response
        } catch (err) {
            Notification.error(err?.response?.data?.error_message)
            return rejectWithValue(err)
        }
    }
)

export const addAchievements = createAsyncThunk(
    'achievements/add',
    async (props, { rejectWithValue }) => {
        try {
            const response = await Request.postJson(`/achievements/`, props.body)
            if (response?.status === 201) {
                Notification.success(response?.data?.success_message)
                history.push('../Members-achievements')
            }
            return response
        } catch (err) {
            Notification.error(err?.response?.data?.error_message)
            return rejectWithValue(err)
        }
    }
)
