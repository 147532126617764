import { AUTH_TOKEN_NAME } from '../config/const';

export default class Storage {
  /**
   * Getter function to set auth-token in localStorage.
   *
   * @param token
   */
  static set token(token) {
    localStorage.setItem(AUTH_TOKEN_NAME, token);
  }

  /**
   * Getter function to get auth-token from localStorage.
   *
   * @returns {string}
   */
  static get token() {
    return localStorage.getItem(AUTH_TOKEN_NAME);
  }

  /**
   * Store an object in browser localStorage.
   *
   * @param {string}key
   * @param {object}object
   */
  static setObject(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  /**
   * Get an object from browser localStorage.
   *
   * @param {string}item
   * @returns {any}
   */
  static getObject(item) {
    return JSON.parse(
      localStorage.getItem(item) !== null ? localStorage.getItem(item) : '{}'
    );
  }

  /**
   * Delete an object from browser localStorage.
   *
   * @param {string}key
   */
  static remove(key) {
    localStorage.removeItem(key);
  }

  /**
   * Set flash message into browser sessionStorage.
   *
   * @param {string}messageKey
   * @param {string}message
   */
  static setFlash(messageKey, message) {
    sessionStorage.removeItem(messageKey);
    sessionStorage.setItem(messageKey, message);
  }

  /**
   *  Check if browser sessionStorage has a key with messageKey.
   *
   * @param {string}messageKey
   * @returns {boolean}
   */
  static hasFlash(messageKey) {
    return !!sessionStorage.getItem(messageKey);
  }

  /**
   * Get a flash message from browser sessionStorage.
   *
   * @param {string}messageKey
   */
  static getFlash(messageKey) {
    const message = sessionStorage.getItem(messageKey);
    sessionStorage.removeItem(messageKey);
    return message;
  }
}
