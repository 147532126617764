import Request from '../helpers/request.service';

// ABOUT US
export function getAboutUsContent() {
  return Request.get('/about/');
}

export function updateAboutUsContent(data) {
  return Request.put('/about/', data);
}

// AD BANNER
export function getAdBannerList() {
  return Request.get('/banner/');
}

export function activateAdBanner(bannerId) {
  return Request.put(`/banners/${bannerId}/`);
}

export function addAdBanner(data) {
  return Request.post('/banner/', { image_data: data });
}

export function deleteAdBanner(bannerId) {
  return Request.delete(`/banners/${bannerId}/`);
}

// COMMUNITY HELP
export function getQueriesList() {
  return Request.get('/help/');
}

export function getFilteredQueriesList(queryTypeId) {
  return Request.get(`/help/${queryTypeId}/`);
}

export function deleteQuery(queryId) {
  return Request.delete(`/help/${queryId}/`);
}

// FEEDBACK
export function getFeedbackList() {
  return Request.get('/feedback/');
}

export function deleteFeedback(feedbackId) {
  return Request.delete(`/feedbacks/${feedbackId}/`);
}

// COMMITTEES
export function getCommitteesList() {
  return Request.get('/committees/');
}

export function updateCommittee(committeeId, data) {
  return Request.put(`/committees/${committeeId}/`, data);
}

export function createCommittee(data) {
  return Request.post('/committees/', data);
}

export function deleteCommittee(committeeId) {
  return Request.delete(`/committees/${committeeId}/`);
}

// NEWS & EVENTS
export function getNewsEventsList() {
  return Request.get('/news/');
}

export function updateNewsEvents(id, data) {
  return Request.put(`/news/${id}/`, data);
}

export function createNewsEvents(data) {
  return Request.post('/news/', data);
}

export function deleteNewsEvents(id) {
  return Request.delete(`/news/${id}/`);
}

// COMMITTEE MEMBERS
export function getCommitteeMembersList() {
  return Request.get('/committee-members/');
}

export function getFilteredCommitteeMembersList(committeeId) {
  return Request.get(`/committee-members/${committeeId}/`);
}

export function updateCommitteeMember(committeeMemberId, data) {
  return Request.put(`/committee-member/${committeeMemberId}/`, data);
}

export function createCommitteeMember(data) {
  return Request.post('/committee-members/', data);
}

export function deleteCommitteeMember(committeeMemberId) {
  return Request.delete(`/committee-member/${committeeMemberId}/`);
}

// MEMBERS
export function getMembersList() {
  return Request.get('/members/?all=true');
}

export function getMemberById(memberId) {
  return Request.get(`/member/${memberId}/`);
}

export function updateMember(memberId, data) {
  return Request.put(`/member/${memberId}/`, data);
}

export function deleteMembers(memberId) {
  return Request.delete(`/member/${memberId}/`);
}

export function approveMember(memberId) {
  return Request.get(`/approve/${memberId}/`);
}

// MASTER
export function getCountries() {
  return Request.get('/countries/');
}

export function getStates(countryId) {
  return Request.get(`/states/${countryId}/`);
}

export function getCities(stateId) {
  return Request.get(`/cities/${stateId}/`);
}

export function getVillages() {
  return Request.get('/villages/');
}

export function getGotra() {
  return Request.get('/gotra/');
}

export function getEducations() {
  return Request.get('/educations/');
}

// SAMAJ DARSHAN
export function getTemples() {
  return Request.get('/temples/');
}

export function getTempleById(templeId) {
  return Request.get(`/temples/${templeId}/`);
}

export function addTemples(data) {
  return Request.post('/temples/', data);
}

export function updateTemples(templeId, data) {
  return Request.put(`/temples/${templeId}/`, data);
}

export function deleteTemples(templeId) {
  return Request.delete(`/temples/${templeId}/`);
}

export function deleteTempleImage(templeImageId) {
  return Request.delete(`/temples/images/${templeImageId}/`);
}
