import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFeedback, getFeedbackList } from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const feedbackListGet = createAsyncThunk(
  'get/feedback',
  async (props, thunkAPI) => {
    try {
      const response = await getFeedbackList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const feedbackDelete = createAsyncThunk(
  'delete/feedback',
  async (props, thunkAPI) => {
    try {
      const response = await deleteFeedback(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
