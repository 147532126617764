import { createSlice } from "@reduxjs/toolkit";
import { sendNotification } from "./notificationAction";

const initialState = {
    loading: false,
    status: '',
    error: ''
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    extraReducers(builders) {
        builders
            .addCase(sendNotification.fulfilled, (state, action) => {
                state.status = action?.payload?.data?.message
                state.loading = false
            })
            .addCase(sendNotification.pending, (state) => {
                state.status = ""
                state.loading = true
                state.error = ""
            })
            .addCase(sendNotification.rejected, (state, action) => {
                state.loading = false
                state.status = ""
                state.error = action?.payload?.response?.data?.message
            })
    }
})

export default notificationSlice.reducer;
