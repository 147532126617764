import { createSlice } from '@reduxjs/toolkit';
import { feedbackDelete, feedbackListGet } from './feedbackAction';

const initialState = {
  feedbackList: [],
  deleteFeedback: [],
  status: '',
  error: ''
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(feedbackListGet.fulfilled, (state, action) => {
        state.feedbackList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(feedbackListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(feedbackListGet.rejected, (state, action) => {
        state.feedbackList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(feedbackDelete.fulfilled, (state, action) => {
        state.deleteFeedback = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(feedbackDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(feedbackDelete.rejected, (state, action) => {
        state.deleteFeedback = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default feedbackSlice.reducer;
