import { createSlice } from '@reduxjs/toolkit';
import {
  newsEventsCreate,
  newsEventsDelete,
  newsEventsListGet,
  newsEventsUpdate
} from './news-eventsAction';

const initialState = {
  newsEventsList: [],
  updateNewsEvents: {},
  createNewsEvents: {},
  deleteNewsEvents: {},
  status: '',
  error: ''
};

const newsEventsSlice = createSlice({
  name: 'newsEvents',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(newsEventsListGet.fulfilled, (state, action) => {
        state.newsEventsList = action?.payload?.success_message;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(newsEventsListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(newsEventsListGet.rejected, (state, action) => {
        state.newsEventsList = [];
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      })

      .addCase(newsEventsUpdate.fulfilled, (state, action) => {
        state.updateNewsEvents = action?.payload?.success_message;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(newsEventsUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(newsEventsUpdate.rejected, (state, action) => {
        state.updateNewsEvents = {};
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      })

      .addCase(newsEventsCreate.fulfilled, (state, action) => {
        state.createNewsEvents = action?.payload?.success_message;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(newsEventsCreate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(newsEventsCreate.rejected, (state, action) => {
        state.createNewsEvents = {};
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      })

      .addCase(newsEventsDelete.fulfilled, (state, action) => {
        state.deleteNewsEvents = action?.payload?.success_message;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(newsEventsDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(newsEventsDelete.rejected, (state, action) => {
        state.deleteNewsEvents = [];
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      });
  }
});

export default newsEventsSlice.reducer;
