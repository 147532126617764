import { createSlice } from '@reduxjs/toolkit';
import {
  adBannerActivate,
  adBannerAdd,
  adBannerDelete,
  adBannerListGet
} from './adBannerAction';

const initialState = {
  bannerList: [],
  activeBanner: '',
  newBanner: '',
  deleteBanner: '',
  status: '',
  error: ''
};

const adBannerSlice = createSlice({
  name: 'adBanner',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(adBannerListGet.fulfilled, (state, action) => {
        state.bannerList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(adBannerListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(adBannerListGet.rejected, (state, action) => {
        state.bannerList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(adBannerActivate.fulfilled, (state, action) => {
        state.activeBanner = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(adBannerActivate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(adBannerActivate.rejected, (state, action) => {
        state.activeBanner = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(adBannerAdd.fulfilled, (state, action) => {
        state.newBanner = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(adBannerAdd.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(adBannerAdd.rejected, (state, action) => {
        state.newBanner = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(adBannerDelete.fulfilled, (state, action) => {
        state.deleteBanner = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(adBannerDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(adBannerDelete.rejected, (state, action) => {
        state.deleteBanner = '';
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default adBannerSlice.reducer;
