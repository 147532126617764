/*
    This file is responsible for creating the Redux store and persisting it.
    The store is created using the Redux Toolkit, which is a recommended
    way to create a Redux store. The store is then persisted using
    redux-persist, which allows us to persist the store in the browser's
    local storage. This allows us to keep the store's state even after
    the page is refreshed.
    For more information, see:
    https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/
 */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import aboutSlice from './about/aboutSlice';
import adBannerSlice from './adBanner/adBannerSlice';
import committeeMembersSlice from './committee-members/committee-membersSlice';
import committeesSlice from './committees/committeesSlice';
import communityHelpSlice from './community-help/communityHelpSlice';
import todoReducer from './features/todo/todoSlice';
import feedbackSlice from './feedback/feedbackSlice';
import loginSlice from './login/loginSlice';
import masterSlice from './master/masterSlice';
import membersSlice from './members/membersSlice';
import newsEventsSlice from './news-events/news-eventsSlice';
import templesSlice from './samaj-darshan/samaj-darshanSlice';
import settingsReducer from './settings/settingsSlice';
import achievementsSlice from './memberAchievements/achievementsSlice';
import notificationSlice from './notifications/notificationSlice';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  todos: todoReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    settings: settingsReducer,
    login: loginSlice,
    about: aboutSlice,
    adBanner: adBannerSlice,
    communityHelp: communityHelpSlice,
    feedback: feedbackSlice,
    committees: committeesSlice,
    newsEvents: newsEventsSlice,
    committeeMembers: committeeMembersSlice,
    members: membersSlice,
    master: masterSlice,
    temples: templesSlice,
    achievements: achievementsSlice,
    notifications: notificationSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store);
