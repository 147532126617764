import { createSlice } from '@reduxjs/toolkit';

export const todoSlice = createSlice({
  name: 'settings',
  initialState: {
    sidebarCollapsed: false
  },
  reducers: {
    toggleSidebar: (state) => {
      return { ...state, sidebarCollapsed: !state.sidebarCollapsed };
    }
  }
});

// this is for dispatch
export const { toggleSidebar } = todoSlice.actions;

// this is for configureStore
export default todoSlice.reducer;
