import { createSlice } from '@reduxjs/toolkit';
import {
  committeeMembersCreate,
  committeeMembersDelete,
  committeeMembersListGet,
  committeeMembersUpdate,
  filteredCommitteeMembersListGet
} from './committee-membersAction';

const initialState = {
  committeeMembersList: [],
  updateCommitteeMember: {},
  createCommitteeMember: {},
  deleteCommitteeMember: {},
  status: '',
  error: ''
};

const committeeMembersSlice = createSlice({
  name: 'committees',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeeMembersListGet.fulfilled, (state, action) => {
        state.committeeMembersList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeeMembersListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeeMembersListGet.rejected, (state, action) => {
        state.committeeMembersList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(filteredCommitteeMembersListGet.fulfilled, (state, action) => {
        state.committeeMembersList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(filteredCommitteeMembersListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(filteredCommitteeMembersListGet.rejected, (state, action) => {
        state.committeeMembersList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeeMembersUpdate.fulfilled, (state, action) => {
        state.updateCommitteeMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeeMembersUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeeMembersUpdate.rejected, (state, action) => {
        state.updateCommitteeMember = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeeMembersCreate.fulfilled, (state, action) => {
        state.createCommitteeMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeeMembersCreate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeeMembersCreate.rejected, (state, action) => {
        state.createCommitteeMember = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeeMembersDelete.fulfilled, (state, action) => {
        state.deleteCommitteeMember = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeeMembersDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeeMembersDelete.rejected, (state, action) => {
        state.deleteCommitteeMember = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default committeeMembersSlice.reducer;
