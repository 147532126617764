import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activateAdBanner,
  addAdBanner,
  deleteAdBanner,
  getAdBannerList
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const adBannerListGet = createAsyncThunk(
  'get/adBanner',
  async (props, thunkAPI) => {
    try {
      const response = await getAdBannerList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const adBannerActivate = createAsyncThunk(
  'update/adBanner',
  async (props, thunkAPI) => {
    try {
      const response = await activateAdBanner(props);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const adBannerAdd = createAsyncThunk(
  'post/adBanner',
  async (props, thunkAPI) => {
    try {
      const response = await addAdBanner(props);
      Notification.success('Successfully uploaded.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const adBannerDelete = createAsyncThunk(
  'delete/adBanner',
  async (props, thunkAPI) => {
    try {
      const response = await deleteAdBanner(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
