import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createNewsEvents,
  deleteNewsEvents,
  getNewsEventsList,
  updateNewsEvents
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const newsEventsListGet = createAsyncThunk(
  'get/newsEvents',
  async (props, thunkAPI) => {
    try {
      const response = await getNewsEventsList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const newsEventsUpdate = createAsyncThunk(
  'update/newsEvents',
  async (props, thunkAPI) => {
    try {
      const response = await updateNewsEvents(props.id, props.data);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const newsEventsCreate = createAsyncThunk(
  'post/newsEvents',
  async (props, thunkAPI) => {
    try {
      const response = await createNewsEvents(props);
      Notification.success('Successfully created.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const newsEventsDelete = createAsyncThunk(
  'delete/newsEvents',
  async (props, thunkAPI) => {
    try {
      const response = await deleteNewsEvents(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
