import { createSlice } from '@reduxjs/toolkit';
import {
  committeesCreate,
  committeesDelete,
  committeesListGet,
  committeesUpdate
} from './committeesAction';

const initialState = {
  committeesList: [],
  updateCommittee: {},
  createCommittee: {},
  deleteCommittee: {},
  status: '',
  error: ''
};

const committeesSlice = createSlice({
  name: 'committees',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(committeesListGet.fulfilled, (state, action) => {
        state.committeesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeesListGet.rejected, (state, action) => {
        state.committeesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeesUpdate.fulfilled, (state, action) => {
        state.updateCommittee = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeesUpdate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeesUpdate.rejected, (state, action) => {
        state.updateCommittee = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeesCreate.fulfilled, (state, action) => {
        state.createCommittee = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeesCreate.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeesCreate.rejected, (state, action) => {
        state.createCommittee = {};
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(committeesDelete.fulfilled, (state, action) => {
        state.deleteCommittee = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(committeesDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(committeesDelete.rejected, (state, action) => {
        state.deleteCommittee = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default committeesSlice.reducer;
