import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  approveMember,
  deleteMembers,
  getMemberById,
  getMembersList,
  updateMember
} from '../../axios/axios';
import { citiesListGet, statesListGet } from '../master/masterAction';

import Notification from '../../helpers/Notification';

export const membersListGet = createAsyncThunk(
  'get/members',
  async (props, thunkAPI) => {
    try {
      const response = await getMembersList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const memberDetailGet = createAsyncThunk(
  'get/member',
  async (props, thunkAPI) => {
    try {
      const response = await getMemberById(props);
      const membersDetail = response.data.data;

      thunkAPI.dispatch(statesListGet(membersDetail?.country));
      thunkAPI.dispatch(citiesListGet(membersDetail?.state));

      return membersDetail;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const memberUpdate = createAsyncThunk(
  'update/member',
  async (props, thunkAPI) => {
    try {
      const response = await updateMember(props.id, props.data);
      Notification.success('Successfully updated.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const memberDelete = createAsyncThunk(
  'delete/members',
  async (props, thunkAPI) => {
    try {
      const response = await deleteMembers(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const approveMemberGet = createAsyncThunk(
  'get/approveMember',
  async (props, thunkAPI) => {
    try {
      const response = await approveMember(props);
      Notification.success(response.data.data);

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.info(error.response.data.status);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
