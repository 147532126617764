import { createSlice } from '@reduxjs/toolkit';

export const todoSlice = createSlice({
  name: 'todos',
  initialState: {
    todoList: []
  },
  reducers: {
    addTodoList: (state, action) => {
      return { ...state, todoList: action.payload };
    }
  }
});

export const getTodoList = (state) => state.todos.todoList;

// this is for dispatch
export const { addTodoList } = todoSlice.actions;

// this is for configureStore
export default todoSlice.reducer;
