import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteQuery,
  getFilteredQueriesList,
  getQueriesList
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const queriesListGet = createAsyncThunk(
  'get/queriesList',
  async (props, thunkAPI) => {
    try {
      const response = await getQueriesList();

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const filteredQueriesListGet = createAsyncThunk(
  'get/filteredQueriesList',
  async (props, thunkAPI) => {
    try {
      const response = await getFilteredQueriesList(props);

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const queryDelete = createAsyncThunk(
  'delete/query',
  async (props, thunkAPI) => {
    try {
      const response = await deleteQuery(props);
      Notification.success('Successfully deleted.');

      return response.data;
    } catch (error) {
      if (error.response.status !== 404) {
        Notification.error('Something went wrong.');
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
