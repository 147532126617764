import { createAsyncThunk } from "@reduxjs/toolkit";
import Notification from "../../helpers/Notification";
import Request from "../../helpers/request.service";

export const sendNotification = createAsyncThunk(
    'post/notification',
    async (props, rejectWithValue) => {
        try {
            const response = await Request.postJson('/push/', props.body)
            Notification.success(response?.data?.message)
            return response;
        }
        catch (error) {
            Notification.error(error?.response?.data?.message)
            return rejectWithValue(error)
        }
    }
)
