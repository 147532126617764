import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { history } from './helpers/history';
import HistoryRouter from './helpers/HistoryRouter';
import { persistor, store } from './redux/store';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HistoryRouter history={history}>
    <Provider store={store}>
      {/* <React.StrictMode>*/}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      {/* </React.StrictMode>*/}
    </Provider>
  </HistoryRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
