import { createSlice } from '@reduxjs/toolkit';
import {
  filteredQueriesListGet,
  queriesListGet,
  queryDelete
} from './communityHelpAction';

const initialState = {
  queriesList: [],
  queryDelete: []
};

const communityHelpSlice = createSlice({
  name: 'communityHelp',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(queriesListGet.fulfilled, (state, action) => {
        state.queriesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(queriesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(queriesListGet.rejected, (state, action) => {
        state.queriesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(filteredQueriesListGet.fulfilled, (state, action) => {
        state.queriesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(filteredQueriesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(filteredQueriesListGet.rejected, (state, action) => {
        state.queriesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(queryDelete.fulfilled, (state, action) => {
        state.queryDelete = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(queryDelete.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(queryDelete.rejected, (state, action) => {
        state.queryDelete = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default communityHelpSlice.reducer;
