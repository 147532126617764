import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCities,
  getCountries,
  getEducations,
  getGotra,
  getStates,
  getVillages
} from '../../axios/axios';

import Notification from '../../helpers/Notification';

export const citiesListGet = createAsyncThunk(
  'get/cities',
  async (props, thunkAPI) => {
    try {
      const response = await getCities(props);

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const countriesListGet = createAsyncThunk(
  'get/countries',
  async (props, thunkAPI) => {
    try {
      const response = await getCountries();

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const educationsListGet = createAsyncThunk(
  'get/educations',
  async (props, thunkAPI) => {
    try {
      const response = await getEducations();

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gotrasListGet = createAsyncThunk(
  'get/gotras',
  async (props, thunkAPI) => {
    try {
      const response = await getGotra();

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const statesListGet = createAsyncThunk(
  'get/states',
  async (props, thunkAPI) => {
    try {
      const response = await getStates(props);

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const villagesListGet = createAsyncThunk(
  'get/villages',
  async (props, thunkAPI) => {
    try {
      const response = await getVillages();

      return response.data;
    } catch (error) {
      //   if (error.response.status !== 404) {
      //     Notification.error('Something went wrong.');
      //   }
      return thunkAPI.rejectWithValue(error);
    }
  }
);
