import { createSlice } from '@reduxjs/toolkit';
import {
  citiesListGet,
  countriesListGet,
  educationsListGet,
  gotrasListGet,
  statesListGet,
  villagesListGet
} from './masterAction';

const initialState = {
  citiesList: [],
  countriesList: [],
  educationsList: [],
  gotraList: [],
  statesList: [],
  villagesList: [],
  status: '',
  error: ''
};

const masterSlice = createSlice({
  name: 'members',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(citiesListGet.fulfilled, (state, action) => {
        state.citiesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(citiesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(citiesListGet.rejected, (state, action) => {
        state.citiesList = [];
        state.error = action?.payload?.response?.data?.error_message;
        state.status = 'rejected';
      })

      .addCase(countriesListGet.fulfilled, (state, action) => {
        state.countriesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(countriesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(countriesListGet.rejected, (state, action) => {
        state.countriesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(educationsListGet.fulfilled, (state, action) => {
        state.educationsList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(educationsListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(educationsListGet.rejected, (state, action) => {
        state.educationsList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(gotrasListGet.fulfilled, (state, action) => {
        state.gotraList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(gotrasListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(gotrasListGet.rejected, (state, action) => {
        state.gotraList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(statesListGet.fulfilled, (state, action) => {
        state.statesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(statesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(statesListGet.rejected, (state, action) => {
        state.statesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      })

      .addCase(villagesListGet.fulfilled, (state, action) => {
        state.villagesList = action?.payload?.data;
        state.error = '';
        state.status = 'fulfilled';
      })
      .addCase(villagesListGet.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(villagesListGet.rejected, (state, action) => {
        state.villagesList = [];
        state.error = action?.payload?.response?.data?.status;
        state.status = 'rejected';
      });
  }
});

export default masterSlice.reducer;
